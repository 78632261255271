import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';

const LANG_KEY = 'en'

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  selected = ''
  supporteLanguages = ['en', 'pt', 'fr']
  hasSelected = null;
  constructor(
    private translate: TranslateService, 
    private storage: Storage,
    ) { }

    setInitialAppLanguage(){
      let language = this.getCurrentLanguage();
      const lang = this.isSupportedLanguage(language) ? language : "en";
      this.translate.setDefaultLang(lang);
      this.selected = lang;

      this.storage.create();
      this.storage.get(LANG_KEY).then( val =>{
        if(val){
          this.setLanguage(val, true);
          this.selected = val
        }
      });
    }

    getLanguages(){
      return [
        {text: 'English', value: 'en'},
        {text: 'Portugues', value: 'pt'},
        {text: 'French', value: 'fr'}
      ];
    }

    getCurrentLanguage(){
      return this.selected;
    }

    getLocale(){
      if(this.selected =="pt"){
        return "pt-PT"
      }
      if(this.selected =="fr"){
        return "fr-FR"
      }
      return "en-GB"
    }

    setLanguage(lng, isOnLoad = false){
      if(this.isSupportedLanguage(lng)){
        this.translate.use(lng);
        this.selected = lng;
        if(!isOnLoad){
          this.hasSelected = lng;
        }
      }
      this.storage.set(LANG_KEY, lng);
    }

    isSupportedLanguage(lng){
      return this.supporteLanguages.indexOf(lng) > -1;
    }
}
