import { Injectable } from '@angular/core';
import * as moment from 'moment';
 
@Injectable({
  providedIn: 'root'
})
export class DateService {
 
  constructor() { }
 
  getDayNumber(n): string {
    return moment().add(n, 'days').format('YYYY-MM-DD');
  }
 
  getTimeHour(): string {
    return moment().format('H');
  }
 
  addDayNumber(day, n) {
    return moment(day).add(n, 'days').valueOf();
  }
 
  getDateFromString(string: string) {
    return moment(string).format("YYYY-MM-DD")
  }
 
  getDateFromTimestamp(timestamp, format: string = 'YYYY-MM-DD', unix: boolean = false) {
    if (unix) {
      return moment.unix(timestamp).format(format);
    }
    return moment(timestamp).format(format);
  }
 
  getDateToStringFormat(date: any, format: string = 'YYYY-MM-DD') {
    return moment(date).format(format);
  }
 
  getTimeDifference(eventDate, eventStarttime): string {
    const duration = moment(`${eventDate}, ${eventStarttime}`, "YYYY-MM-DD, h:mm:ss a").fromNow();
    return duration;
  }
 
  isTimeAfter(time: string, timeToCheck: string){
    if(time === timeToCheck){
      return true;
    }
    return moment(time, 'HH:mm').isAfter(moment(timeToCheck, 'HH:mm'));
  }

  addHour(time: string){
    return moment(time, 'HH:mm').add(1, 'hours').format( 'HH:mm');
  }

  getThisWeekDay(weekday){
    const thisWeekday = this.getToday().weekday();
    if(thisWeekday === 0){
      weekday = weekday - 7
    }
    // console.log("weekday: " + weekday)
    // console.log("thisWeekday: " + thisWeekday)
    if(weekday >= thisWeekday){
      const daysToAdd = weekday - thisWeekday;
      return moment().add(daysToAdd, 'days');
    }
    const daysToRemove = (thisWeekday - weekday);
    // console.log(moment().subtract(daysToRemove, 'days'));
    return moment().subtract(daysToRemove, 'days');

  }

  getTimeDurationBetween(start, end){
   return moment.duration(moment(end, 'HH:mm').diff(moment(start, 'HH:mm')));
  }

  getTime(time){
    return moment(time, 'HH:mm');
  }

  getToday(){
    return moment();
  }

  getNow(){
    return moment.now();
  }

  getDate(date){
    return moment(date)
  }

  getDashFormatDate(date, format = "YYYY-MM-DD"){
    return moment(date, format);
  }
 
  getMomentFormat(dateStr: string, format: string) {
    return moment(dateStr).format(format);
  }
 
  getMoment(){
    return moment().format();
  }

  getDayWeekIndex(day){
    const date = new Date(day);
    const utcDay = date.getUTCDay();
    return utcDay > 0 ? (utcDay-1) : 6
  }
}

