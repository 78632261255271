import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { SelectOptionSearchComponent } from 'src/app/components/select-option-search/select-option-search.component';
import { InstagramEmbeddedPostComponent } from 'src/app/components/instagram-embedded-post/instagram-embedded-post.component';



@NgModule({
  declarations: [FooterComponent, HeaderComponent, SelectOptionSearchComponent, InstagramEmbeddedPostComponent],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule
  ],
  exports: [FooterComponent, HeaderComponent, SelectOptionSearchComponent, InstagramEmbeddedPostComponent]
})
export class SharedModuleModule { }
