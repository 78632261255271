import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {

  constructor(
    private storage: Storage,
    private helpers: HelpersService,
    private modalCtrl: ModalController,) { }

  ngOnInit() {}

  saveCookieConsent(consent){
    this.storage.set(this.helpers.COOKIE_CONSENT, consent);
    this.modalCtrl.dismiss();
  }

}
