// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // firebase: {
  //   apiKey: "AIzaSyCeYt9X2kUYfJhuDsyvmx5FwMpBcU9DT2Q",
  //   authDomain: "marca-test.firebaseapp.com",
  //   databaseURL: "https://marca-test-default-rtdb.europe-west1.firebasedatabase.app",
  //   projectId: "marca-test",
  //   storageBucket: "marca-test.appspot.com",
  //   messagingSenderId: "353870429770",
  //   appId: "1:353870429770:web:ecc5b02a4360ecb18a30b3",
  //   measurementId: "G-QJBL5LX2KR"
  // },
  firebase: {
    projectId: 'marca-f727b',
    appId: '1:901845356552:web:a90c43196436ec339e41bd',
    databaseURL: 'https://marca-f727b-default-rtdb.europe-west1.firebasedatabase.app',
    storageBucket: 'marca-f727b.appspot.com',
    apiKey: 'AIzaSyAlQWuM-c1JYDsxMgOvQjf666RL3Plyisg',
    authDomain: 'marca-f727b.firebaseapp.com',
    messagingSenderId: '901845356552',
    measurementId: 'G-JC5TWK1FL9',
  },
  production: false,
  cookieDomain: 'localhost'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
