import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { DateService } from '../date/date.service';
import { LanguageService } from '../language/language.service';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  scheduleObject = [
    { name: this.translate.instant("common.monday"),
      shortName: this.translate.instant("common.monday_short_name"),
      formName: 'monday',
      index: 0,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},
     
    { name: this.translate.instant("common.tuesday"),
      shortName: this.translate.instant("common.tuesday_short_name"),
      formName: 'tuesday',
      index: 1,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},

    { name: this.translate.instant("common.wednesday"),
      shortName: this.translate.instant("common.wednesday_short_name"),
      formName: 'wednesday',
      index: 2,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},

    { name: this.translate.instant("common.thursday"),
      shortName: this.translate.instant("common.thursday_short_name"),
      formName: 'thursday',
      index: 3,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},

    { name: this.translate.instant("common.friday"),
      shortName: this.translate.instant("common.friday_short_name"),
      formName: 'friday',
      index: 4,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},

    { name: this.translate.instant("common.saturday"),
      shortName: this.translate.instant("common.saturday_short_name"),
      formName: 'saturday',
      index: 5,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},
     
    { name: this.translate.instant("common.sunday"),
      shortName: this.translate.instant("common.sunday_short_name"),
      formName: 'sunday',
      index: 6,
      startTime: "Closed",
      endTime: "Closed",
      open: false,
      class: "off"},
  ];

  times = [0,15,30,45];

  paymentMethod =[
    {
    id: 0,
    name: this.translate.instant("payment_method.bank_transfer"),
    active: true
    },
    {  
      id: 1,
      name: this.translate.instant("payment_method.monthly_payments"),
      active: false
    }
  ]

  subscriptionPackages = [
    {
      name: "umson",
      price: 10,
      users: 1,
      description: this.translate.instant("subscription.package_umson_description")
    },
    {
      name: "dinos",
      price: 15,
      users: 3,
      description: this.translate.instant("subscription.package_dinos_description")
    },
    {
      name: "fami",
      price: 30,
      users: 6,
      description: this.translate.instant("subscription.package_fami_description")
    }
  ]

  scheduleTimeRanges = [ 
    {description: this.getTranslate("7"), days: 7},
    {description: this.translate.instant("common.2_week"), days: 14},
    {description: this.translate.instant("common.3_week"), days: 21},
    {description: this.translate.instant("common.1_month"), days: 30},
    {description: this.translate.instant("common.2_month"), days: 60},
    {description: this.translate.instant("common.3_month"), days: 90},
    {description: this.translate.instant("common.4_month"), days: 180},
  ]

    // {description: "Current Month only", days: "1M"},

  COOKIE_CONSENT = 'cookieConsent';
  minDate:any = this.dateCtrl.getToday().format('YYYY-MM-DD');
  maxDate:any = this.dateCtrl.getToday().add(3 ,'M').format('YYYY-MM-DD');


  constructor(
    public alertController: AlertController,
    private authService: AuthService,
    public dateCtrl: DateService,
    public translate: TranslateService,
    public language: LanguageService) { }

  async showAlert ( header, message){
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  getTranslate(str){
    this.translate.instant(`common.${str}`)
  }

  async presentLoginAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant("helpers.present_loging_alert_message"),
      inputs: [
        {
          name: "email",
          placeholder: this.translate.instant("forms.email_placeholder"),
          type: 'text'
        },
        {
          name: "password",
          placeholder: this.translate.instant("forms.password_placeholder"),
          type: 'password',
          attributes: {
            minLength: 6,
          },
        }
      ],
      buttons: [
        {
          text: 'Login',
          handler: data => {
            this.authService.login(data).then(()=>{
              this.showAlert(this.translate.instant("common.info"), this.translate.instant("common.try_again"));
            })
          }
        },
      ]
    });
    await alert.present();
  }

  showFormError(form: FormGroup, control: string){
      return (form.controls[control].dirty || form.controls[control].touched ) && form.controls[control].errors
  }

  formatTimeToIso(time: string){
    return '2020-11-21T' + time;
  }

  convertEmailToKey(email: string){
    return this.encrypt(email.replace(".", "_").replace("@", "_")).replaceAll("/", "_");;
  }

  convertPhoneNumberToKey(phonenumber: string){
    return this.encrypt(phonenumber);
  }

  clearInputSpaces(string:string, form:any, input:any){
    form.controls[input].setValue(string.trim().replace(".", ""));
  }


  getPackagePrice(price, currencyCode){
    if(currencyCode === "EUR"){
      return price
    }

    if(currencyCode === "USD" || currencyCode === "CAD"){
      return (price + 10)
    }

    return (price + 5)
  }

  getPackagePriceSymbol(currencyCode, currencySymbol){
    if(currencyCode === "USD" || currencyCode === "CAD" || currencyCode === "EUR"){
      return currencySymbol
    }
    return "£"
  }

  getPackageFromName(packageName){
    return this.subscriptionPackages.find(subscriptionPackage => subscriptionPackage.name === packageName)
  }

  getLangFromCountry(country:string){
    switch(country.toLocaleLowerCase()) { 
      case "portugal": { 
        return "pt"; 
      }
      case "france": { 
        return "fr"; 
      }
      default: { 
        return "en";
      } 
   } 
  }

  encrypt(text:string){
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
  };

  getMonthShortName(){
    const monhtsList =['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December']
    const monthsTransaltedList = []
    monhtsList.forEach((month)=>{
      monthsTransaltedList.push(this.translate.instant(`common.${month.toLocaleLowerCase()}_short_name`))
    })
    return monthsTransaltedList;
  }

  getFirstNameWithSurnameChar(name:string){
    const nameSplited = name.split(" ")
    if(nameSplited.length > 1){
      return nameSplited[0] + " " + nameSplited[1].charAt(0).toUpperCase();
    }
    return nameSplited[0]
  }

  getTimeRange(days:any){
    return this.scheduleTimeRanges.find( element => element.days == days)
  }

  reloadPage(){
    window.location.reload();
  }

  getInstaPostCodeFromLink(instagramLink:string){
    const arr = instagramLink.split("/");
    const arrLength = arr.length
    return arr[arrLength-2]
  }

}
