import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  lng:string = 'en';

  constructor(private language: LanguageService) { }

  ngOnInit() {
    this.lng =  this.language.getCurrentLanguage();
  }

  setLanguage(event:any){
    this.language.setLanguage(event.detail.value)
  }

}
