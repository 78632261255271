import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-select-option-search',
  templateUrl: './select-option-search.component.html',
  styleUrls: ['./select-option-search.component.scss'],
})
export class SelectOptionSearchComponent implements OnInit {


  @Input('items') items: any;
  @Input('fieldName') fieldName: any;
  @Input('otherFieldName') otherFieldName: any;
  @Input('searchPlaceHolder') searchPlaceHolder: any;
  isArray: boolean = false;
  

  itemsList: any;
  itemsFiltered: any;

  constructor(
    private modalCtrl: ModalController, ) { 
  }

  ngOnInit() {
    if(this.items instanceof Array){
      this.itemsList = JSON.parse(JSON.stringify(this.items));
      this.itemsFiltered = JSON.parse(JSON.stringify(this.items));
    } else {
      const arr = [];
      for (const property in this.items) {
          arr.push(this.items[property])
      }
      this.itemsList = JSON.parse(JSON.stringify(arr));
      this.itemsFiltered = JSON.parse(JSON.stringify(arr));
    }
  }

  search(searchString:string){
    const items = JSON.parse(JSON.stringify(this.itemsList));
    this.itemsFiltered = items
    .filter((item) => {
      if(this.otherFieldName){
        return item[this.fieldName].toLowerCase().indexOf(searchString.toLowerCase()) > -1 || item[this.otherFieldName].toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      }
      return item[this.fieldName].toLowerCase().indexOf(searchString.toLowerCase()) > -1;
      });
  }

  select(itemSelected){
    this.modalCtrl.dismiss(itemSelected);
  }

  close(){
    this.modalCtrl.dismiss();
  }

}
