import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

@Component({
  selector: 'app-instagram-embedded-post',
  templateUrl: './instagram-embedded-post.component.html',
  styleUrls: ['./instagram-embedded-post.component.scss'],
})
export class InstagramEmbeddedPostComponent implements OnInit {

  @Input('instagramPostCode') instagramPostCode:string = "Cqk4tTooJvI";
  displayCloseButton:boolean = false;

  test:any;

  constructor(
    private modalCtrl: ModalController,
    private helpers: HelpersService,) { }

  ngOnInit() {
    const element = document.getElementsByTagName("blockquote");
    element[0].setAttribute("data-instgrm-permalink", `https://www.instagram.com/p/${this.instagramPostCode}/?utm_source=ig_embed&amp;utm_campaign=loading`)
    if (window['instgrm']){
        
        window['instgrm'].Embeds.process();

    }

    var intervalVar = setInterval(function () {
      this.test = window['instgrm'];
    }.bind(this),500)
  }

  close() {
    return this.modalCtrl.dismiss();
  }

}
