import { Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  sendPasswordResetEmail, deleteUser,
  sendEmailVerification, updateEmail, updatePassword, updateProfile} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: Auth) { 
  }

  async register({email, password}) {
    try {
      const user = await createUserWithEmailAndPassword(this.auth, email, password);
      return user
    } catch (e) {
      return null
    }
  }

  async login({email, password}) {
    try {
      const user = await signInWithEmailAndPassword(this.auth, email, password);
      return user
    } catch (e) {
      return null
    }
  }

  currentUser(){
    return this.auth
  }

  logout() {
    return signOut(this.auth)
  }

  async sendEmailVerification() {
    const user = this.auth.currentUser;
      if(!user.emailVerified){
        try {
          const emailVerification = await sendEmailVerification(this.auth.currentUser);
          return emailVerification;
        } catch (e) {
          return null
        }}
      return null
    }

    async updateName(displayName){
      return updateProfile( this.auth.currentUser, {displayName: displayName})
    }

    async updateEmailAddress(newEmail){
      return updateEmail( this.auth.currentUser, newEmail)
    }

    async updatePassword(newPassword){
      return updatePassword(this.auth.currentUser, newPassword)

    }

async deleteAccount(){
  return deleteUser(this.auth.currentUser)
}

}
