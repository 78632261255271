import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate} from '@angular/fire/auth-guard';

const redirectUnauthorizedToHome = () => redirectUnauthorizedTo(['home'])
const redirectLoggedInToAdminDashboard = () => redirectLoggedInTo(['admin-dashboard'])

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    ...canActivate(redirectLoggedInToAdminDashboard)
  },
  {
    path: 'message/:id',
    loadChildren: () => import('./view-message/view-message.module').then( m => m.ViewMessagePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login-register/login-register.module').then( m => m.LoginRegisterPageModule),
    ...canActivate(redirectLoggedInToAdminDashboard)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/login-register/login-register.module').then( m => m.LoginRegisterPageModule),
    ...canActivate(redirectLoggedInToAdminDashboard)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/login-register/login-register.module').then( m => m.LoginRegisterPageModule),
    ...canActivate(redirectLoggedInToAdminDashboard)
  },

  {
    path: 'admin-dashboard',
    loadChildren: () => import('./pages/admin-dashboard/admin-dashboard.module').then( m => m.AdminDashboardPageModule),
    ...canActivate(redirectUnauthorizedToHome)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./pages/client-bookings/client-bookings.module').then( m => m.ClientBookingsPageModule)
  },
  {
    path: 'shop/:id',
    loadChildren: () => import('./pages/shop/shop.module').then( m => m.ShopPageModule)
  },
  {
    path: 'about/:section',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'termsAndConditions',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  { 
    path: "**",     
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
