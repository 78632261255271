import { Component } from '@angular/core';
import { initializeApp } from '@angular/fire/app';
import { ModalController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { LanguageService } from './services/language/language.service';

import { HelpersService } from './services/helpers/helpers.service';
import { CookieConsentComponent } from './components/cookie-consent/cookie-consent.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private languageService: LanguageService,
    private modalCtrl: ModalController,
    private storage: Storage,
    private helpers: HelpersService
    ) {
    this.initializeApp();
  }

  initializeApp(){
    this.platform.ready().then(()=>{
      this.languageService.setInitialAppLanguage();
      this.checkCookie();
      // this.storage.set(this.helpers.COOKIE_CONSENT, null);
    })
  }

  checkCookie(){
    this.storage.get(this.helpers.COOKIE_CONSENT).then( val =>{
      if(!val){
        this.openCookieConsentModal();
      }
    });
  }

  async openCookieConsentModal(){
    const modal = await this.modalCtrl.create({
      component: CookieConsentComponent,
      componentProps: {
        
      },
      backdropDismiss: false,
      showBackdrop: true,
      cssClass: 'cookie-consent-modal-class'
    });
    modal.present();
  }

}
